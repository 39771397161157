<template>
  <div>
    <div>
      <div class="flex items-center mb-2">
        <div class="items-center hodo-el hidden">
          <el-date-picker
            v-model="selectedDate"
            type="date"
            class="mx-1 hodo-el-date-picker"
            format="dd/MM/yyyy"
            :clearable="false"
            :picker-options="pickerOptions"
            @change="changeDate"
          ></el-date-picker>
        </div>
        <!-- <div class="ml-auto hidden">
          <button
            class="flex items-center justify-center border-0 focus:outline-none bg-blue-700 text-white rounded-lg h-9 px-3 py-2"
            type="button"
            @click="onShowAppointmentModal()"
          >
            <i class="el-icon-collection mr-2"></i>
            {{ $t("Lịch hẹn đã đặt trước") }}
          </button>
        </div>-->
      </div>
      <div class="row">
        <div class="col-lg-8">
          <nav class="navbar navbar-expand-lg bg-transparent py-0">
            <a class="navbar-brand d-lg-none" href="#">
              <img
                src="../../../public/assets/images/icon/appointment.svg"
                alt
                class="mr-2 page-icon"
              />
              <span class="txt-pri fs-14 fs-16 fw-500">
                {{ $t("schedule_page.lbl_working_schedule") }}
              </span>
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav h-full">
                <li
                  class="nav-item h-full transition-all duration-200"
                  v-for="(tab, index) in tabControls"
                  :key="index"
                  :class="
                    tabActive === tab.status
                      ? 'active bg-white hodo-shadow rounded-t-md'
                      : ''
                  "
                >
                  <div
                    role="button"
                    class="nav-link fs-16 fw-500"
                    @click="setTabActive(tab.status)"
                  >
                    <span
                      :class="tabActive === tab.status ? 'text-blue-800' : ''"
                      >{{ tab.label }} ({{ tab.total }})</span
                    >
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div
          class="col-lg-4 flex align-items-center justify-content-end mt-1 mt-lg-0"
        >
          <div
            class="inline-block mr-3 cursor-pointer hover:text-blue-700 tranistion-all duration-200"
            @click="handleResetFilters"
          >
            <i class="el-icon-close mr-1" />
            <span>Xoá bộ lọc</span>
          </div>
          <button
            class="flex items-center justify-center border-0 focus:outline-none bg-blue-700 text-white rounded-lg h-9 px-3 py-2"
            type="button"
            @click="onShowRegisterAppointment()"
          >
            <span class="fa fa-plus mr-2"></span>
            {{ $t("Tạo mới lịch hẹn") }}
          </button>
        </div>
      </div>
    </div>
    <div class="relative card radius-0 border-0">
      <div
        :class="isLoading ? 'visible opacity-100' : 'invisible opacity-0'"
        class="absolute bottom-2 left-0 py-2 px-2 rounded-r-md bg-green-100 text-xs transition-all duration-200"
      >
        <span>{{ $t("Đang cập nhật...") }}</span>
      </div>

      <div class="px-2 pt-2">
        <CustomFilters
          search-box-class="w-1/4"
          :show-status="false"
          :show-payment="false"
          :show-quick-date="true"
          :default-filters="examinationFiltersParams"
          @setFilters="setExaminationFiltersParams"
          :is-max-date-today="false"
          :is-date-range="true"
          :isRefresh="refreshFilters"
        />
      </div>

      <PatientVisitTabContent
        :show-being-examination="true"
        v-if="tabActive === EXAMINATION_STATUS.CODE.WAITING"
        :tabActive="tabActive"
        :fields="waitingFieldsTable"
        :list-data="listWaitingData"
        :being-examination-data="listBeingData"
        :meta-data="waitingDataMeta"
        @refresh="onRefresh"
        @onSetLimit="handleChangeLimit"
        @handlePageClick="handlePageClick"
      />
      <PatientVisitTabContent
        v-else-if="tabActive === EXAMINATION_STATUS.CODE.WAITING_READ_RESULT"
        :tabActive="tabActive"
        :fields="waitingReadResultFieldsTable"
        :list-data="listWaitingReadResultData"
        :being-examination-data="listBeingData"
        :meta-data="waitingReadResultDataMeta"
        @refresh="onRefresh"
        @onSetLimit="handleChangeLimit"
        @handlePageClick="handlePageClick"
      />
      <PatientVisitTabContent
        v-else-if="tabActive === EXAMINATION_STATUS.CODE.DONE"
        :tabActive="tabActive"
        :fields="finishedFieldsTable"
        :list-data="listDoneData"
        :being-examination-data="listBeingData"
        :meta-data="doneDataMeta"
        @refresh="onRefresh"
        @onSetLimit="handleChangeLimit"
        @handlePageClick="handlePageClick"
      />
      <PatientVisitTabContent
        :show-being-examination="true"
        v-else-if="tabActive === EXAMINATION_STATUS.CODE.MISSING"
        :tabActive="tabActive"
        :fields="waitingFieldsTable"
        :list-data="listMissingData"
        :being-examination-data="listBeingData"
        :meta-data="missingDataMeta"
        @refresh="onRefresh"
        @onSetLimit="handleChangeLimit"
        @handlePageClick="handlePageClick"
      />
    </div>

    <!-- <custom-modal
      :showModal="showAppointmentModal"
      @hideModal="hideModal"
      :title="$t('Lịch hẹn đã được đặt trước')"
      :count="appointmentMetaData && appointmentMetaData.count"
    >
      <appointment-modal
        v-if="showAppointmentModal"
        :items="appointmentsData"
        :metaData="appointmentMetaData"
        @setFilters="setFiltersAppointment"
        :refreshFilters="refreshFilters"
        @refresh="onRefresh"
      />
    </custom-modal>-->

    <custom-modal
      :showModal="showRegisterAppointment"
      @hideModal="hideModal"
      :title="$t('Đăng ký lịch hẹn')"
    >
      <template v-if="showRegisterAppointment">
        <appointment-form v-show="step === 'input'" @nextStep="nextStep" />
        <appointment-confirm
          v-if="step === 'confirm'"
          :data="apptDataRaw"
          @backStep="step = 'input'"
          :returnRoute="returnRoute"
          @success="handleSuccess"
        />
      </template>
    </custom-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AppointmentConfirm from '../../components/Appointment/v2/AppointmentConfirm'
import AppointmentForm from '../../components/Appointment/v2/AppointmentForm'
import CustomFilters from '../../components/CustomFilters'
import CustomModal from '../../components/CustomModal'
import appUtils from '../../utils/appUtils'
import {
  APPOINTMENT_METHOD_STATUS,
  APPOINTMENT_STATUS,
  EXAMINATION_STATUS,
  INVOICE_STATUS,
  PATIENT_RECEPTION_TYPE
} from '../../utils/constants'
import mdtUtils from '../../utils/mdtUtils'
import AppointmentModal from '../Appointment/V2/AppointmentModal'
import PatientVisitTabContent from './TabContent/index.vue'

export default {
  name: 'PatientVisitManagement',
  components: {
    PatientVisitTabContent,
    CustomModal,
    AppointmentModal,
    AppointmentForm,
    AppointmentConfirm,
    CustomFilters
  },
  data () {
    const tabControls = [
      {
        label: this.$t('Chờ khám'),
        slug: 'waiting',
        class: ``,
        total: 0,
        status: EXAMINATION_STATUS.CODE.WAITING
      },
      {
        label: this.$t('Chờ đọc kết quả'),
        slug: 'waiting-read-result',
        class: ``,
        total: 0,
        status: EXAMINATION_STATUS.CODE.WAITING_READ_RESULT
      },
      {
        label: this.$t('Đã khám xong'),
        slug: 'finished-examination',
        class: ``,
        total: 0,
        status: EXAMINATION_STATUS.CODE.DONE
      },
      {
        label: this.$t('Nhỡ lượt'),
        slug: 'missing',
        class: ``,
        total: 0,
        status: EXAMINATION_STATUS.CODE.MISSING
      }
    ]

    return {
      isLoading: false,
      tabControls,
      tabActive: tabControls[0].status,
      defaultFilter: {
        fromDate: window.moment().format('YYYY-MM-DD'),
        toDate: window.moment().format('YYYY-MM-DD')
      },
      waitingFieldsTable: [
        {
          key: 'line_number',
          label: this.$t('STT'),
          thClass: 'text-nowrap text-sm text-center',
          tdClass: 'align-middle text-sm text-center',
          sortable: true
        },
        // {
        //   key: 'start_time',
        //   label: this.$t('Thời gian'),
        //   thClass: 'text-nowrap text-sm',
        //   tdClass: 'align-middle text-sm',
        //   sortable: true
        // },
        {
          key: 'person.name',
          label: this.$t('Họ và tên'),
          thClass: 'text-nowrap text-sm',
          tdClass: 'align-middle text-sm',
          sortable: true
        },
        {
          key: 'consulting_room.name',
          label: this.$t('Phòng tư vấn'),
          thClass: 'text-nowrap text-sm',
          tdClass: 'align-middle text-sm',
          sortable: true
        },
        {
          key: 'method',
          label: this.$t('Hình thức khám'),
          thClass: 'text-nowrap text-sm',
          tdClass: 'align-middle text-sm',
          sortable: true
        },
        // {
        //   key: 'invoice.status',
        //   label: this.$t('Thanh toán'),
        //   thClass: 'text-nowrap text-sm',
        //   tdClass: 'align-middle text-sm',
        //   sortable: true
        // },
        // {
        //   key: 'status',
        //   label: this.$t('Trạng thái'),
        //   thClass: 'text-nowrap text-sm',
        //   tdClass: 'align-middle text-sm',
        //   sortable: true
        // },
        {
          key: 'actions',
          label: this.$t('Hành động'),
          thClass: 'text-nowrap text-sm',
          tdClass: 'align-middle text-sm',
          sortable: false
        }
      ],
      waitingReadResultFieldsTable: [
        {
          key: 'line_number',
          label: this.$t('STT'),
          thClass: 'text-nowrap text-sm text-center',
          tdClass: 'align-middle text-sm text-center',
          sortable: true
        },
        // {
        //   key: 'start_time',
        //   label: this.$t('Thời gian'),
        //   thClass: 'text-nowrap text-sm',
        //   tdClass: 'align-middle text-sm',
        //   sortable: true
        // },
        {
          key: 'person.name',
          label: this.$t('Họ và tên'),
          thClass: 'text-nowrap text-sm',
          tdClass: 'align-middle text-sm',
          sortable: true
        },
        {
          key: 'consulting_room.name',
          label: this.$t('Phòng tư vấn'),
          thClass: 'text-nowrap text-sm',
          tdClass: 'align-middle text-sm',
          sortable: true
        },
        {
          key: 'method',
          label: this.$t('Hình thức khám'),
          thClass: 'text-nowrap text-sm',
          tdClass: 'align-middle text-sm',
          sortable: true
        },
        // {
        //   key: 'invoice.status',
        //   label: this.$t('Thanh toán'),
        //   thClass: 'text-nowrap text-sm',
        //   tdClass: 'align-middle text-sm',
        //   sortable: true
        // },
        {
          key: 'status',
          label: this.$t('Trạng thái'),
          thClass: 'text-nowrap text-sm',
          tdClass: 'align-middle text-sm',
          sortable: true
        },
        {
          key: 'actions',
          label: this.$t('Hành động'),
          thClass: 'text-nowrap text-sm',
          tdClass: 'align-middle text-sm',
          sortable: false
        }
      ],
      finishedFieldsTable: [
        {
          key: 'line_number',
          label: this.$t('STT'),
          thClass: 'text-nowrap text-sm text-center',
          tdClass: 'align-middle text-sm text-center',
          sortable: true
        },
        // {
        //   key: 'start_time',
        //   label: this.$t('Thời gian'),
        //   thClass: 'text-nowrap text-sm',
        //   tdClass: 'align-middle text-sm',
        //   sortable: true
        // },
        {
          key: 'person.name',
          label: this.$t('Họ và tên'),
          thClass: 'text-nowrap text-sm',
          tdClass: 'align-middle text-sm',
          sortable: true
        },
        {
          key: 'consulting_room.name',
          label: this.$t('Phòng tư vấn'),
          thClass: 'text-nowrap text-sm',
          tdClass: 'align-middle text-sm',
          sortable: true
        },
        {
          key: 'method',
          label: this.$t('Hình thức khám'),
          thClass: 'text-nowrap text-sm',
          tdClass: 'align-middle text-sm',
          sortable: true
        }
        // {
        //   key: 'invoice.status',
        //   label: this.$t('Thanh toán'),
        //   thClass: 'text-nowrap text-sm',
        //   tdClass: 'align-middle text-sm',
        //   sortable: true
        // },
        // {
        //   key: 'status',
        //   label: this.$t('Trạng thái'),
        //   thClass: 'text-nowrap text-sm',
        //   tdClass: 'align-middle text-sm',
        //   sortable: false
        // }
      ],
      limit: 10,
      listAllData: [],
      listWaitingData: [],
      waitingDataMeta: {},
      waitingPage: 1,
      listBeingData: [],
      beingDataMeta: {},
      beingPage: 1,
      listWaitingResultData: [],
      listWaitingReadResultData: [],
      waitingReadResultDataMeta: {},
      waitingReadResultPage: 1,
      listDoneData: [],
      doneDataMeta: {},
      donePage: 1,
      listCanceledData: [],
      listMissingData: [],
      missingDataMeta: {},
      missingPage: 1,
      metaData: null,
      appointmentsData: [],
      appointmentMetaData: null,
      examinationFiltersParams: null,
      appointmentFilterParams: null,
      appUtils,
      mdtUtils,
      APPOINTMENT_STATUS,
      EXAMINATION_STATUS,
      APPOINTMENT_METHOD_STATUS,
      INVOICE_STATUS,
      showAppointmentModal: false,
      showRegisterAppointment: false,
      step: 'input',
      apptDataRaw: null,
      returnRoute: {
        name: 'AppointmentDetailV2',
        query: {
          type: PATIENT_RECEPTION_TYPE.CODE.APPOINTMENT
        }
      },
      isRefreshFilter: false,
      selectedDate: window.moment(),
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: this.$t('Hôm nay'),
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: this.$t('Ngày hôm qua'),
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: this.$t('Tuần trước'),
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      }
    }
  },
  watch: {
    '$route.query': {
      handler: function (query) {
        if (query?.tab) {
          this.handleMountedSetActive(query)
        }
      }
      // deep: true,
      // immediate: true
    },
    examinationFiltersParams: {
      handler: function (data) {
        console.log(data?.doctor)
        const queryRouteRaw = {
          ...this.$route.query,
          ...data,
          doctor: data?.doctor || 'All'
        }
        const { payment, page, search, status, ...queryRoute } = queryRouteRaw

        window.localStorage.setItem(
          'patientVisitRoute',
          JSON.stringify(queryRoute)
        )
        this.$router
          .replace({
            query: queryRoute
          })
          .catch(() => {})
      },
      deep: true,
      immediate: true
    }
  },

  async created () {
    const self = this
    const data = self.tabControls.find(
      (item) => item.slug === self.$route.query?.tab
    )

    const patientVisitRoute = appUtils.isJSON(
      window.localStorage.getItem('patientVisitRoute')
    )
      ? JSON.parse(window.localStorage.getItem('patientVisitRoute'))
      : {}
    self.examinationFiltersParams = {
      ...self.defaultFilter,
      status:
        patientVisitRoute.status ||
        data?.status ||
        EXAMINATION_STATUS.CODE.WAITING,
      doctor:
        patientVisitRoute?.doctor ||
        Number(self.$route.query?.doctor) ||
        Number(self.$user?.doctor?.id) ||
        'All',
      room: patientVisitRoute.room || Number(self.$route.query?.room) || 'All',
      fromDate:
        patientVisitRoute.fromDate ||
        self.$route.query?.fromDate ||
        self.moment().format('YYYY-MM-DD'),
      toDate:
        patientVisitRoute.toDate ||
        self.$route.query?.toDate ||
        self.moment().format('YYYY-MM-DD')
    }

    await self.preloadExamination({
      limit: 1,
      show: false
    })
    await self.handleGetExamination(
      self.tabActive || EXAMINATION_STATUS.CODE.WAITING
    )
    await self.handleGetExamination(EXAMINATION_STATUS.CODE.BEING)

    self.timer = setInterval(async () => {
      await self.preloadExamination({
        limit: self.limit,
        show: true
      })
    }, 1000 * 30) // refetch 30s
  },
  computed: {
    ...mapGetters({
      waitingExaminations: 'appointmentStore/allWaitingExaminations',
      waitingExaminationMeta: 'appointmentStore/allWaitingExaminationMeta'
    }),
    refreshFilters () {
      const data = this.tabControls.find(
        (item) => item.slug === this.$route.query?.tab
      )

      if (this.isRefreshFilter) {
        this.examinationFiltersParams = {
          ...this.defaultFilter,
          ...this.examinationFiltersParams,
          status: data?.status || EXAMINATION_STATUS.CODE.WAITING
          // doctor: Number(this.$user?.doctor?.id),
          // room: 'All'
        }
      }

      return this.isRefreshFilter
    }
  },
  mounted () {
    const self = this
    self.handleMountedSetActive(self.$route.query)
    window.scroll(0, 0)
    setTimeout(() => {
      let user = self.$user
      let clinic = self.$globalClinic
      self.sendAmplitude('Open Patient Visit List', {
        'doctor id':
          user && user?.doctor && user?.doctor?.id ? user?.doctor?.id : '',
        'doctor name': user && user.name ? user.name : '',
        'workspace id': clinic && clinic.id ? clinic.id : '',
        'workspace name': clinic && clinic.name ? clinic.name : '',
        timestamp: self.moment(new Date()).format('HH:mm DD-MM-YYYY')
      })
    }, 100)
  },
  methods: {
    ...mapActions('appointmentStore', [
      'getAppointmentRequestV2',
      'getWaitingExaminationRequest'
    ]),
    cancelAutoUpdate () {
      clearInterval(this.timer)
    },
    handleMountedSetActive (query) {
      if (!query.tab) return

      const data = this.tabControls.find((item) => item.slug === query.tab)

      if (!data || typeof data === 'undefined') return

      this.setTabActive(data.status)
      this.handleGetExamination(data.status)
    },
    setTabActive (value) {
      this.tabActive = Number(value)

      const data = this.tabControls.find(
        (item) => item.status === Number(value)
      )

      if (!data || typeof data === 'undefined') {
        return
      }

      if (this.$route.query?.tab === data.slug) return

      this.$router
        .replace({
          query: {
            ...this.$route.query,
            tab: data.slug
            // status: data?.status
          }
        })
        .catch(() => {})
    },
    setParamsData ({ limit = 10, data }) {
      let params = {
        page_size: limit,
        sort_by: 'start_time',
        order: 'asc',
        page_num: data?.page || 1,
        clinic_id: this.$clinicId,
        status: data?.status
      }

      if (data?.search?.length) {
        params.search = data.search
      }

      if (
        (data?.fromDate && !data?.toDate) ||
        (!data?.fromDate && data?.toDate)
      ) {
        return
      }

      if (data?.fromDate && data?.toDate) {
        params.start_time = this.moment(data?.fromDate)
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss')
        params.end_time = this.moment(data?.toDate)
          .endOf('day')
          .format('YYYY-MM-DD HH:mm:ss')
      } else {
        params.start_time = this.moment()
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss')
        params.end_time = this.moment(this.moment().add(6, 'months'))
          .endOf('day')
          .format('YYYY-MM-DD HH:mm:ss')
      }

      if (Number(data?.room)) {
        params.cr_id = Number(data?.room)
      }

      if (Number(data?.payment) > 0) {
        params.invoice_status = Number(data?.payment)
      }

      if (Number(data?.doctor) > 0) {
        params.doctor_id = Number(data?.doctor)
      }

      return params
    },
    async getWaitingExaminations ({ limit = 10, params = {}, refetch = true }) {
      try {
        let paramsData = this.setParamsData({
          limit,
          data: params
        })

        this.isLoading = true

        const res = await this.getWaitingExaminationRequest({
          params: paramsData,
          refetch
        })

        return res
      } catch (error) {
        console.log(error)
        this.isLoading = false
      } finally {
        this.isLoading = false
      }
    },
    onRefresh (id) {
      this.preloadExamination({
        limit: this.limit
      })
      if (id) this.sendNotification(id)
    },
    async sendNotification (id) {
      try {
        await this.$rf
          .getRequest('DoctorRequest')
          .sendNotification({ patient_visit_id: id })
      } catch (error) {
        console.log(error)
      }
    },
    setExaminationFiltersParams (params) {
      const { status, ...newParams } = params

      this.waitingPage = 1
      this.waitingReadResultPage = 1
      this.donePage = 1
      this.missingPage = 1

      this.examinationFiltersParams = {
        ...this.examinationFiltersParams,
        ...newParams,
        page: 1
      }
      this.preloadExamination({
        limit: this.limit
      })
    },
    hideModal () {
      this.showAppointmentModal = false
      this.isRefreshFilter = true

      this.showRegisterAppointment = false
    },
    onShowRegisterAppointment () {
      this.showRegisterAppointment = true
    },
    nextStep (data) {
      if (!data) return
      this.apptDataRaw = data
      this.step = 'confirm'
    },
    changeDate () {
      this.waitingPage = 1
      this.waitingReadResultPage = 1
      this.donePage = 1
      this.missingPage = 1
      this.examinationFiltersParams = {
        ...this.examinationFiltersParams,
        fromDate: this.selectedDate,
        toDate: this.selectedDate,
        page: 1
      }
      this.$router
        .push({
          query: {
            date: window.moment(this.selectedDate).format('DD-MM-YYYY')
          }
        })
        .catch(() => {})
      this.onRefresh()
    },
    handleChangeLimit (value) {
      this.limit = value
      this.preloadExamination({
        limit: this.limit
      })
    },
    setTotalTabControl (status, total) {
      const data = this.tabControls.find((tab) => tab.status === status)
      if (!data) return

      data.total = total
    },
    async getExaminationWaiting ({ limit = 10, params = {}, show = true }) {
      const status = EXAMINATION_STATUS.CODE.WAITING

      this.listWaitingData = [...this.listWaitingData]

      const res = await this.getWaitingExaminations({
        limit,
        params: {
          ...this.examinationFiltersParams,
          ...params,
          page: this.waitingPage,
          status
        }
      })
      const { data, ...resp } = res

      if (show) {
        this.waitingDataMeta = resp?.page
        this.listWaitingData = [...data]
      }

      const total = resp?.page?.total

      this.setTotalTabControl(status, total)
    },
    async getExaminationBeing ({ limit = 10, params = {}, show = true }) {
      const status = EXAMINATION_STATUS.CODE.BEING

      this.listBeingData = [...this.listBeingData]

      const res = await this.getWaitingExaminations({
        limit,
        params: {
          ...this.examinationFiltersParams,
          ...params,
          status
        }
      })
      const { data, ...resp } = res

      if (show) {
        this.beingDataMeta = resp?.page
        this.listBeingData = data
      }

      const total = resp?.page?.total

      this.setTotalTabControl(status, total)
    },
    async getExaminationWaitingReadResult ({
      limit = 10,
      params = {},
      show = true
    }) {
      const status = EXAMINATION_STATUS.CODE.WAITING_READ_RESULT

      this.listWaitingReadResultData = [...this.listWaitingReadResultData]

      const res = await this.getWaitingExaminations({
        limit,
        params: {
          ...this.examinationFiltersParams,
          ...params,
          page: this.waitingReadResultPage,
          status
        }
      })
      const { data, ...resp } = res

      if (show) {
        this.waitingReadResultDataMeta = resp?.page
        this.listWaitingReadResultData = data
      }

      const total = resp?.page?.total

      this.setTotalTabControl(status, total)
    },
    async getExaminationDone ({ limit = 10, params = {}, show = true }) {
      const status = EXAMINATION_STATUS.CODE.DONE

      this.listDoneData = [...this.listDoneData]

      const res = await this.getWaitingExaminations({
        limit,
        params: {
          ...this.examinationFiltersParams,
          ...params,
          page: this.donePage,
          status
        }
      })
      const { data, ...resp } = res

      if (show) {
        this.doneDataMeta = resp?.page
        this.listDoneData = data
      }

      const total = resp?.page?.total

      this.setTotalTabControl(status, total)
    },
    async getExaminationMissing ({ limit = 10, params = {}, show = true }) {
      const status = EXAMINATION_STATUS.CODE.MISSING

      this.listMissingData = [...this.listMissingData]

      const res = await this.getWaitingExaminations({
        limit,
        params: {
          ...this.examinationFiltersParams,
          ...params,
          page: this.missingPage,
          status
        }
      })
      const { data, ...resp } = res

      if (show) {
        this.missingDataMeta = resp?.page
        this.listMissingData = data
      }

      const total = resp?.page?.total

      this.setTotalTabControl(status, total)
    },
    async preloadExamination ({ limit = this.limit || 10, show = true }) {
      await Promise.all([
        this.getExaminationBeing({
          limit,
          show
        }),
        this.getExaminationWaiting({
          limit,
          show
        }),
        this.getExaminationWaitingReadResult({
          limit,
          show
        }),
        this.getExaminationDone({
          limit,
          show
        }),
        this.getExaminationMissing({
          limit,
          show
        })
      ])
    },
    handlePageClick (data) {
      switch (data?.status) {
        case EXAMINATION_STATUS.CODE.WAITING:
          this.waitingPage = data?.page || 1
          this.getExaminationWaiting({
            limit: this.limit
          })
          break
        case EXAMINATION_STATUS.CODE.WAITING_READ_RESULT:
          this.waitingReadResultPage = data?.page || 1
          this.getExaminationWaitingReadResult({
            limit: this.limit
          })
          break
        case EXAMINATION_STATUS.CODE.DONE:
          this.donePage = data?.page || 1
          this.getExaminationDone({
            limit: this.limit
          })
          break
        case EXAMINATION_STATUS.CODE.MISSING:
          this.missingPage = data?.page || 1
          this.getExaminationMissing({
            limit: this.limit
          })
          break
        default:
          break
      }
    },
    async handleGetExamination (status) {
      this.examinationFiltersParams = {
        ...this.examinationFiltersParams,
        status
      }
      switch (status) {
        case EXAMINATION_STATUS.CODE.WAITING:
          await Promise.all([
            await this.getExaminationWaiting({
              limit: this.limit || 10,
              show: true
            }),
            await this.getExaminationBeing({
              limit: this.limit || 10,
              show: true
            })
          ])
          break
        case EXAMINATION_STATUS.CODE.WAITING_READ_RESULT:
          await this.getExaminationWaitingReadResult({
            limit: this.limit || 10,
            show: true
          })
          break
        case EXAMINATION_STATUS.CODE.DONE:
          await this.getExaminationDone({
            limit: this.limit || 10,
            show: true
          })
          break
        case EXAMINATION_STATUS.CODE.MISSING:
          await Promise.all([
            await this.getExaminationMissing({
              limit: this.limit || 10,
              show: true
            }),
            await this.getExaminationBeing({
              limit: this.limit || 10,
              show: true
            })
          ])
          break
        case EXAMINATION_STATUS.CODE.BEING:
          await this.getExaminationBeing({
            limit: this.limit || 10,
            show: true
          })
          break
        default:
          break
      }
    },
    handleSuccess () {
      this.step = 'input'
      this.showRegisterAppointment = false
      this.getExaminationWaiting({ limit: 10, show: true })
    },
    handleResetFilters () {
      const data = this.tabControls.find(
        (item) => item.slug === this.$route.query?.tab
      )
      this.isRefreshFilter = true
      const params = {
        ...this.defaultFilter,
        status: data?.status || EXAMINATION_STATUS.CODE.WAITING,
        doctor: Number(this.$user?.doctor?.id) || 'All',
        room: 'All'
      }
      this.examinationFiltersParams = params
      this.setExaminationFiltersParams(params)
    }
  },
  destroyed () {
    this.cancelAutoUpdate()
  }
}
</script>
<style scoped lang="scss">
::v-deep {
  .hodo-shadow {
    box-shadow: rgba(35, 46, 60, 0.04) 0 2px 4px 0;
  }

  .nav-item a {
    color: #7386c7 !important;
  }

  .nav-item.active a {
    color: #20419b !important;
  }

  .navbar-expand-lg.navbar-light .nav-item.active:after {
    border-color: #20419b !important;
  }

  .min-h50 {
    min-height: 50vh;
  }

  .w-135px {
    width: 135px !important;
  }

  .hodo-table .table th,
  .hodo-table .table td {
    cursor: pointer;
  }

  .hodo-table .table th {
    color: black !important;
    background-color: #e4e7ec !important;
    font-size: 14px !important;
  }

  .hodo-table .table-hover tbody tr:hover {
    background-color: #ffffff;
  }

  .hodo-el {
    &-date-picker {
      width: 140px !important;
    }

    .el-input__inner {
      background: white !important;
      border: 1px solid #dee2e6 !important;
    }
  }
}
</style>
